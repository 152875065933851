import { useEffect, useState } from 'react';
import useWebSocket from 'react-use-websocket';
import { Side } from 'shared-types';

import useSessionStore from 'hooks/stores/useSessionStore';

import useProductsStore from './stores/useProductsStore';

const WEB_SOCKET_URL = process.env.REACT_APP_WEB_SOCKET_URL || 'ws://localhost:8080';

// Custom reusable hook
const useRealTimeUpdateSidePreview = () => {
  const sessionId = useSessionStore((state) => state.sessionId);
  const updateSidePreview = useProductsStore((state) => state.updateSidePreview);
  const [error, setError] = useState('');

  const { readyState, lastMessage } = useWebSocket(
    `${WEB_SOCKET_URL}/${sessionId}/assets/previewImage`,
  );

  const isVisible = readyState === 1 || readyState === 2;

  useEffect(() => {
    if (lastMessage) {
      console.log("preview image websocket triggered!");
      const { data } = lastMessage;
      const { sideId, previewImageUrl, resizeMetadata, productId, error } = JSON.parse(data) as {
        sideId: string;
        productId: string;
        previewImageUrl: string;
        resizeMetadata: Side['resizeMetadata'];
        error?: string;
      };

      if (error) {
        setError(error);
        return;
      }

      updateSidePreview({ sideId, resizeMetadata, previewImageUrl, productId });
    }
  }, [lastMessage, updateSidePreview]);

  return { error, isVisible };
};

export default useRealTimeUpdateSidePreview;
